<div class="nav-container">
  <app-navbar></app-navbar>
</div>
<div class="h-100 top-standard" data-aos="fade-down" data-aos-duration="2000">
  <div class="container">
    <ul class="grid cs-style-1 m-0 px-0 pt-3 pt-md-5 pb-0">
      <li
        *ngFor="let project of projects"
        class="rounded mx-0 my-3 mx-md-3 h-100"
        style="background: #f9f9f9"
      >
        <h5 class="border-bottom">{{ project.name }}</h5>
        <figure class="">
          <img
            [src]="project.img"
            class="card-img-top img-fluid rounded pb-1"
            [alt]="project.name"
          />
          <figcaption
            class="row"
            [ngStyle]="{ 'background-color': project.bgColor }"
          >
            <div class="row px-0 mx-0">
              <div class="col-12 px-1 pt-2 pt-md-3 px-md-3">
                <p class="mb-1 mb-md-3">{{ project.text }}</p>
              </div>
              <div class="col-3 px-1 px-md-3">
                <img
                  src="../../../assets/img/icons/tools.png"
                  alt=""
                  style="height: 40px"
                />
              </div>
              <div class="col-9 px-1 px-md-3">
                <p class="mb-1 mb-md-3">{{ project.tools }}</p>
              </div>
            </div>
            <div class="mt-3 pr-0 w-100 d-flex justify-content-end">
              <a
                class="links p-2"
                [href]="project.npm ? project.npm : project.demo"
                target="_blank"
                >{{ project.npm ? "NPM" : "DEMO" }}</a
              >
              <a class="links p-2 ml-2" [href]="project.code" target="_blank"
                >CÓDIGO</a
              >
            </div>
          </figcaption>
        </figure>
      </li>
    </ul>
  </div>
  <div class="mt-5">
    <app-footer></app-footer>
  </div>
</div>
