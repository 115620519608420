<div class="nav-container">
  <app-navbar></app-navbar>
</div>
<section class="pt-5">
  <div
    class="container height-contact d-flex align-items-center justify-content-center"
  >
    <div class="row my-5">
      <div class="col-12 top-resp">
        <h4
          class="m-3 pt-md-5"
          data-aos="zoom-in"
          data-aos-duration="2000"
          data-aos-once="true"
        >
          {{ "CONTACT.title" | translate }}
        </h4>
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-5 mt-3 order-2 order-md-1"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <div class="text-justify">
          <div>
            <a href="mailto:gisellemachadoyes@gmail.com" title="title"
              ><p class="my-email m-3">gisellemachadoyes@gmail.com</p></a
            >
          </div>
          <h6 class="contact-text m-3">{{ "CONTACT.message" | translate }}</h6>
        </div>
        <div class="text-break p-3 rounded mt-4">
          <h6>{{ "CONTACT.subtitle" | translate }}</h6>
          <a href="https://github.com/gisellem22" target="_blank">
            <img
              class="img-fluid icon-contact"
              width="60"
              src="../../../assets/img/icons/github-gi.png"
              alt="logo whatsapp"
              title="Github"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/giselle-machado-gm/"
            target="_blank"
          >
            <img
              class="img-fluid ml-3 icon-contact"
              width="60"
              src="../../../assets/img/icons/linkeding-gi.png"
              alt="logo whatsapp"
              title="Linkedin"
            />
          </a>
          <!-- <a
            href="https://app.talento.laboratoria.la/profile/OJAbsIJC1eNW1u5yyoZ7ClSZ4Fp1"
            target="_blank"
          >
            <img
              class="img-fluid ml-3 icon-contact"
              width="60"
              src="../../../assets/img/icons/lab.png"
              alt="logo whatsapp"
              title="APP Talento Laboratoria"
            />
          </a> -->
        </div>
      </div>
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-7 order-1 order-md-2"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <div class="text-center text-md-right">
          <img
            src="../../../assets/img/giphone.png"
            class="img-fluid"
            alt="phone"
            style="height: 300px"
          />
        </div>
      </div>
    </div>
  </div>
</section>
