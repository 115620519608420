<div class="container h-100">
  <div class="h-100 d-flex justify-content-center align-items-center">
    <div class="row">
      <div
        class="col-12 text-center"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="3000"
      >
        <img
          src="../../../assets/img/logogrande.png"
          style="max-height: 400px"
          class="img-fluid"
          alt="logo-GM"
          routerLink="/projects"
          routerLinkActive="active"
        />
      </div>
      <div
        class="col-12 text-center mt-5"
        data-aos="zoom-in-down"
        data-aos-easing="linear"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="3000"
      >
        <a
          class="btn-start p-3"
          routerLink="/projects"
          routerLinkActive="active"
          >VER MIS PROYECTOS</a
        >
      </div>
    </div>
  </div>
</div>
