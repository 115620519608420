<section class="container">
<h5 class="my-5" data-aos="zoom-in" data-aos-duration="2000" data-aos-once="true">{{'SKILLS.title' | translate}}</h5>
<div class="row d-flex justify-content-center mt-2 mb-5 h-100">
    <div class="col-sm-12 col-md card border-0 icon-skills pt-3"
    *ngFor="let skill of skills">
        <div class="iconos-box rounded-top">
            <!-- <div class="icons"><i [class]="skill.icon"></i></div> -->
            <img [src]="skill.img" width="60" alt="">
        </div>
        <div class="card-body rounded-bottom">
            <h6 class="card-title">{{skill.name}}</h6>
        </div>
    </div>
</div>
<div class="row justify-content-center mt-2 mb-5 h-100">
    <div class="col-sm-12 col-md card border-0 icon-skills pt-3"
    *ngFor="let skill2 of skills2">
        <div class="iconos-box rounded-top">
            <!-- <div class="icons"><i [class]="skill.icon"></i></div> -->
            <img [src]="skill2.img" width="60" alt="">
        </div>
        <div class="card-body rounded-bottom">
            <h6 class="card-title">{{skill2.name}}</h6>
        </div>
    </div>
</div>
</section>
<!--data-aos="zoom-in" data-aos-duration="2000"-->
<!--data-aos="fade-down" data-aos-duration="2000"-->
<!-- <img [src]="skill.icon" class="card-img-top img-fluid" [alt]="skill.name"> -->