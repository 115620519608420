<footer>
  <div class="d-flex justify-content-center align-items-center h-100">
    <p class="mb-0 text-center px-4">
      © {{ currentYear }} Created and Designed by
      <a href="https://www.linkedin.com/in/giselle-machado-gm/" target="_blank"
        >Giselle Machado</a
      >. All rights reserved.
    </p>
  </div>
</footer>
