<nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
  <div class="mt-2">
    <img
      src="../../../assets/img/logogi2.png"
      class="main-logo"
      alt="EN CONSTRUCCIÓN"
      routerLink="/home"
      routerLinkActive="active"
    />
  </div>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse flex-row-reverse" id="navbarNav">
    <ul class="navbar-nav">
      <li
        class="nav-item mr-5 text-center"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link px-3"
          [ngClass]="{ 'link-active-pink': currentUrl === 'projects' }"
          routerLink="/projects"
          routerLinkActive="active"
        >
          <img
            src="../../../assets/img/icons/project-gii.png"
            style="height: 60px"
            alt=""
          />
          <p class="mb-0">{{ "NAVBAR.project" | translate }}</p>
        </a>
      </li>
      <li
        class="nav-item mr-5 text-center"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link px-3"
          [ngClass]="{ 'link-active-pink': currentUrl === 'about' }"
          routerLink="/about"
          routerLinkActive="active"
        >
          <img
            src="../../../assets/img/icons/gigi-dev.png"
            style="height: 60px"
            alt=""
          />
          <p class="mb-0">{{ "NAVBAR.about" | translate }}</p>
        </a>
      </li>
      <li
        class="nav-item mr-5 text-center"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link px-3"
          [ngClass]="{ 'link-active-pink': currentUrl === 'contact' }"
          routerLink="/contact"
          routerLinkActive="active"
        >
          <img
            src="../../../assets/img/icons/contact-gi.png"
            style="height: 60px"
            alt=""
          />
          <p class="mb-0">{{ "NAVBAR.contact" | translate }}</p>
        </a>
      </li>
      <!-- <li>
        <div class="mt-4 ml-5">
          <select class="form-control" id="exampleFormControlSelect1"
          (change)="changeLanguaje($event)" >
            <option *ngFor="let item of languages" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </li> -->
    </ul>
  </div>
</nav>
